import React, { FC } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import NavHeader from '../components/NavHeader';
import PostFooter from '../components/PostFooter';
import Summary from '../components/Summary/Summary';
import useSiteMetadata from '../hooks/useSiteMetadata';
import './category.scss';
import { GetPostsByTagQuery } from '../../graphql-types';

interface Props {
  data: GetPostsByTagQuery;
  pageContext: any;
}

const Category: FC<Props> = ({
  data,
  pageContext,
}: Props) => {
  const { title: siteTitle } = useSiteMetadata();
  const { tag } = pageContext;
  const resultCount = data.allMarkdownRemark?.nodes?.length || 0;

  const articles = data.allMarkdownRemark.nodes.map(({ frontmatter }) => {
    return {
      id: frontmatter?.slug,
      title: frontmatter?.title,
      image: getImage(frontmatter?.thumbnail as any),
      imageAltText: 'thumbnail',
      description: frontmatter?.excerpt,
      slug: frontmatter?.slug,
      publishedDate: frontmatter?.date,
    } as any;
  });

  return (
    <Layout
      title={siteTitle}
      description={tag}
      isIndex={false}
      tags={[tag]}
    >
      <div className="category">
        <NavHeader />
        <h3 className="category__infoHeader">
          <span className="category__infoHeader--highlight">{`'${tag}'`}</span>
          {' - '}
          {`${resultCount} result${resultCount > 1 ? 's' : ''} found...`}
        </h3>
        <div className="category__summaryContainer">
          <Summary articles={articles} />
        </div>
        <PostFooter />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query GetPostsByTag($tag: String) {
    allMarkdownRemark(
      filter: {frontmatter: {tags: {eq: $tag}, status: {ne: "draft"}}}
    ) {
      nodes {
        frontmatter {
          title
          excerpt
          date
          slug
          tags
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export default Category;
